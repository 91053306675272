import React, { useContext } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded'

import stepIcon1 from '../../images/step_icon_1.svg'
import stepIcon1Filled from '../../images/step_icon_1_filled.svg'
import stepIcon2 from '../../images/step_icon_2.svg'
import stepIcon2Filled from '../../images/step_icon_2_filled.svg'
import stepIcon3 from '../../images/step_icon_3.svg'
import stepIcon3Filled from '../../images/step_icon_3_filled.svg'
import stepIcon4 from '../../images/step_icon_4.svg'
import stepIcon4Filled from '../../images/step_icon_4_filled.svg'

import { AppContext } from '../../App'

const style = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: { lg: 300, sm: 300, xs: 200 }
  },
  flexColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  divider: {
    border: 'none',
    height: '1rem',
    width: '100%'
  },
  stepsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 800,
    minHeight: 100
  },
  stepsIcon: {
    height: 100,
    marginTop: '.5rem'
  },
  stepsTextContainer: {
    width: 200,
    height: 50,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  stepsText: {
    fontSize: '1.1em',
    fontWeight: 600,
    color: theme => theme.palette.primary.main,
    textAlign: 'center',
    lineHeight: 1.2,
  },
  numberCircle: {
    p: 2,
    borderRadius: 10,
    height: 48,
    width: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1
  },
  number: {
    fontWeight: 600,
    color: '#fff',
    fontSize: '1.2em',
    zIndex: 1
  },
  numberArrow: {
    color: theme => theme.palette.secondary.main,
    position: 'relative',
    left: -27,
    top: 43,
  },
  dotted: {
    border: 'none',
    borderTop: `3px dotted`,
    borderColor: theme => theme.palette.secondary.main,
    width: 600,
    position: 'relative',
    top: -182,
  }
}

export default function Steps() {
  const { state, breakSm } = useContext(AppContext)

  const steps = [
    {
      step: '1',
      text: 'Step 1 - Consent',
      path: '/consent',
      icon: state.profile ? stepIcon1Filled : stepIcon1
    },
    {
      step: '2',
      text: 'Step 2 - Identity Verification',
      path: '/info',
      icon: state.profile?.score ? stepIcon2Filled : stepIcon2
    },
    {
      step: '3',
      text: 'Step 3 - Bank Accounts',
      path: '/accounts',
      icon: state.accounts?.length ? stepIcon3Filled : stepIcon3
    },
    {
      step: '4',
      text: 'Step 4 - VantageScore4<sup>plus</sup>',
      path: '/vs4',
      icon: state.computeScore ? stepIcon4Filled : stepIcon4
    },
  ]

  const getBgColor = (curStep) => {
    const curPath = window.location.pathname
    const curPathStep = steps.find(s => s.path === curPath)
    if (Number(curPathStep.step) >= Number(curStep)) {
      return theme => theme.palette.secondary.main
    }
    return theme => theme.palette.primary.main
  }

  if (breakSm) {
    const pathname = window.location.pathname
    const curStep = steps.find(s => s.path === pathname)
    if (!curStep) return
    return (
      <Box sx={style.container}>
        <Box
          sx={{
            ...style.numberCircle,
            bgcolor: getBgColor(curStep.step)
          }}
        >
          <Typography sx={style.number}>
            {curStep.step}
          </Typography>
        </Box>
        <img src={curStep.icon} alt='steps' style={style.stepsIcon} />
        <Box sx={style.stepsTextContainer}>
          {window.location.pathname === curStep.path &&
          <Typography
            sx={style.stepsText}
            dangerouslySetInnerHTML={{ __html: curStep.text }}
          >
            {/*{window.location.pathname === curStep.path && curStep.text}*/}
          </Typography>
          }
        </Box>
      </Box>
    )
  }

  return (
    <Box sx={style.container}>
      <Box sx={style.stepsRow}>
        {steps.map((s, idx) => (
          <Box key={`steps-box-${idx}`} sx={style.flexColumn}>
            {idx !== 0
              ? <ArrowRightRoundedIcon sx={style.numberArrow} fontSize='large' />
              : <ArrowRightRoundedIcon sx={{ color: 'transparent' }} fontSize='large' />}
            <Box
              sx={{
                ...style.numberCircle,
                bgcolor: getBgColor(s.step)
              }}
            >
              <Typography sx={style.number}>
                {s.step}
              </Typography>
            </Box>
            <img src={s.icon} alt='steps' style={style.stepsIcon} />
            <Box sx={style.stepsTextContainer}>
              {window.location.pathname === s.path &&
              <Typography
                sx={style.stepsText}
                dangerouslySetInnerHTML={{ __html: s.text }}
              >
                {/*{window.location.pathname === s.path && s.text}*/}
              </Typography>
              }
            </Box>
          </Box>
        ))}
      </Box>
      <Box sx={style.dotted} />
    </Box>
  )
}
