import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '.25rem',
  p: 3,
  minWidth: '20rem',
  maxWidth: '20rem'
}

export default function Loading() {
  return (
    <Modal
      open={true}
      aria-labelledby='modal-alert-title'
      aria-describedby='modal-alert-description'
    >
      <Box sx={style}>
        <Typography variant='h6' sx={{ fontWeight: 600 }}>
          Working on it..
        </Typography>
        <Typography variant='text' component='div' sx={{ my: 2 }}>
          Do not close nor refresh the page.
        </Typography>
      </Box>
    </Modal>
  )
}
