import './App.css'
import React, {
  createContext,
  useEffect,
  useReducer,
  useState
} from 'react'
import {
  Routes,
  Route,
  useNavigate
} from 'react-router-dom'

import Home from './components/page/home'
import Login from './components/page/login'
import LoadJWT from './components/page/load_jwt'
import ScoreForm from './components/page/score_form'
import Consent from './components/page/consent'
import Accounts from './components/page/accounts'
import Vs4 from './components/page/vs4'
import AppLoading from './components/page/app_loading'
import MessageModal from './components/modal/message'
import LoadingModal from './components/modal/loading'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import {
  getJWT,
  verifyJWT,
  setJWT,
  removeJWT,
  decodeJWT
} from './util/jwt'
import api from './util/api'
import CssBaseline from '@mui/material/CssBaseline'
import useMediaQuery from '@mui/material/useMediaQuery'


export const AppContext = createContext()

const theme = createTheme({
  palette: {
    primary: {
      main: '#F05F22',
    },
    secondary: {
      main: '#1590D1'
    },
    gradient: {
      main: 'linear-gradient(to right, #FE3633, #F1955B)',
    }
  },
  typography: {
    fontFamily: 'Sofia Sans'
  },
  components: {
    MuiButton: {
      styleOverrides: {
        customOutlined: {
          color: '#30457C',
          borderRadius: '20px',
          padding: '5px 20px',
          border: '1px solid #30457C'
        },
        customOutlinedWhite: {
          color: '#fff',
          borderRadius: '20px',
          padding: '5px 20px',
          border: '2px solid #fff',
          fontWeight: 600
        },
        customContained: {
          color: '#fff',
          borderRadius: '20px',
          padding: '8px 20px',
          background: 'linear-gradient(to right, #FE3633, #F1955B)',
          textTransform: 'none',
          width: 'unset',
          fontWeight: 600,
          minWidth: 100
        },
        customContainedDisabled: {
          color: '#58595B',
          borderRadius: '20px',
          padding: '8px 20px',
          background: 'linear-gradient(to right, #ACADAD, 70%, #fff)',
          textTransform: 'none',
          width: 'unset',
          fontWeight: 600,
          minWidth: 100
        },
        customContainedS: {
          color: '#fff',
          borderRadius: '20px',
          padding: '4px 16px',
          background: 'linear-gradient(to right, #FE3633, #F1955B)',
          textTransform: 'none',
          fontWeight: 600,
          minWidth: 70
        },
      }
    }
  },
})

// const drawerWidth = 240

const initialState = {
  webJWT: null,
  loginJWT: null,
  decodedJWT: null,
  profile: null,
  accounts: [],
  computeScore: null,
  msgModal: null,
  loadingModal: null,
  accsLoading: false,
  profLoading: false,
  appLoading: true
}

const reducer = (state = initialState, action) => {
  const newState = { ...state }
  switch (action.type) {
    case 'set':
      newState[action.state] = action.value
      return newState
    case 'show_loading':
      newState['loadingModal'] = <LoadingModal />
      return newState
    case 'close_loading':
      newState['loadingModal'] = null
      return newState
    case 'reset':
      return initialState
    default:
      return newState
  }
}

const App = () => {
  const [appLoading, setAppLoading] = useState(true)
  const [state, dispatch] = useReducer(reducer, initialState)
  const navigate = useNavigate()

  const breakXs = useMediaQuery(theme.breakpoints.down('xs'))
  const breakSm = useMediaQuery(theme.breakpoints.down('sm'))
  const breakMd = useMediaQuery(theme.breakpoints.down('md'))
  const breakLg = useMediaQuery(theme.breakpoints.down('lg'))


  const getToken = async () => {
    const token = getJWT()
    if (!token) return

    const verify = await verifyJWT(token)
    if (!verify) return
    return token
  }

  const handleLogout = () => {
    dispatch({ type: 'reset' })
    removeJWT()
    navigate('/')
  }

  const fetchToken = async (loginJWT) => {
    const data = { login_token: loginJWT }
    try {
      const resp = await api.token(data)
      dispatch({
        type: 'set',
        state: 'webJWT',
        value: resp.data
      })
      setJWT(resp.data)
    }
    catch (e) {
      console.log('Error signing up', e)
    }
  }

  const computeScore = async (webJWT) => {
    try {
      const resp = await api.compute(webJWT)
      if (resp.data) {
        dispatch({
          type: 'set',
          state: 'computeScore',
          value: resp.data['score']
        })
      }
    }
    catch (e) {
      console.log('Error computing score', e)
    }
  }

  const fetchProfile = async (webJWT) => {
    dispatch({
      type: 'set',
      state: 'profLoading',
      value: true
    })
    try {
      const resp = await api.profile(webJWT)
      if (!resp.data) return
      dispatch({
        type: 'set',
        state: 'profile',
        value: resp.data
      })
    }
    catch (e) {
      console.log('Error fetching profile', e)
    }
    finally {
      dispatch({
        type: 'set',
        state: 'profLoading',
        value: false
      })
    }
  }

  const fetchAccounts = async (webJWT) => {
    dispatch({
      type: 'set',
      state: 'accsLoading',
      value: true
    })
    try {
      const resp = await api.accounts(webJWT)
      if (!resp.data) return
      dispatch({
        type: 'set',
        state: 'accounts',
        value: resp.data.accounts
      })
    }
    catch (e) {
      console.log('Error fetching accounts', e)
    }
    finally {
      dispatch({
        type: 'set',
        state: 'accsLoading',
        value: false
      })
    }
  }

  useEffect(() => {
    const init = async () => {
      const token = await getToken()
      if (!token) {
        setAppLoading(false)
        return
      }
      dispatch({
        type: 'set',
        state: 'webJWT',
        value: token
      })
      dispatch({
        type: 'set',
        state: 'decodedJWT',
        value: decodeJWT(token)
      })
    }
    init()
  }, [])

  useEffect(() => {
    const init = async () => {
      await fetchProfile(state.webJWT)
      await fetchAccounts(state.webJWT)
      setAppLoading(false)
    }
    if (state.webJWT) {
      init()
    }
  }, [state.webJWT])

  const showAlert = (message, disableOk=false) => {
    if (!message) {
      dispatch({
        type: 'set',
        state: 'msgModal',
        value: null
      })
    }
    dispatch({
      type: 'set',
      state: 'msgModal',
      value: (
        <MessageModal content={message} disableOk={disableOk} />
      )
    })
  }

  const renderAlerts = () => {
    if (state.loadingModal) {
      return state.loadingModal
    }
    if (state.msgModal) {
      return state.msgModal
    }
    return
  }

  const value = {
    state,
    dispatch,
    showAlert,
    handleLogout,
    fetchToken,
    fetchProfile,
    fetchAccounts,
    computeScore,
    breakLg,
    breakMd,
    breakSm,
    breakXs
  }

  const renderRoutes = () => {
    if (appLoading) {
      return <AppLoading />
    }
    return (
      <Routes>
        {state.loginJWT || state.webJWT ?
          <>
            <Route path='/' element={<Home />} />
              {state.profile &&
                <>
                  <Route path='/info' element={<ScoreForm />} />
                  <Route path='/accounts' element={<Accounts />} />
                  <Route path='/vs4' element={<Vs4 />} />
                </>
              }
            <Route path='/consent' element={<Consent />} />
            <Route path='*' element={<Home />} />
          </> :
          <>
            <Route path='/login' element={<Login />} />
            <Route path='/login/:jwt' element={<LoadJWT />} />
            <Route path='*' element={<Login />} />
          </>
        }
      </Routes>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={value}>
        <CssBaseline />
        {renderRoutes()}
        {renderAlerts()}
      </AppContext.Provider>
    </ThemeProvider>
  )
}

export default App
