import React, {
  useContext,
  useReducer,
  useEffect,
  useRef
} from 'react'
import { useNavigate } from 'react-router'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import Stack from '@mui/material/Stack'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

import { AppContext } from '../../App'
import api from '../../util/api'
import PageWrapper from './page_wrapper'
import Steps from './steps'
import states from '../../util/states.json'

const style = {
  container: {
    minHeight: '90vh',
    width: '100%',
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: {
      lg: 'center',
      sm: 'flex-start',
      xs: 'flex-start'
    },
    alignItems: 'center'
  },
  title: {
    fontWeight: 600,
    width: 400,
  },
  form: {
    bgcolor: 'background.paper',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'justify',
    width: { lg: 600, sm: 600, xs: 'auto' }
  },
  formField: {
    display: 'flex',
    flexDirection: 'column',
  },
  formButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    m: 1
  },
  formBg: {
    bgcolor: '#ECECEC',
    display: 'flex',
    flexDirection: 'column',
    pt: 1,
    pb: 2,
    px: 4,
    borderRadius: '8px',
    width: '100%',
  },
  formLabel: {
    fontSize: '1.2em',
    color: theme => theme.palette.primary.main,
    fontWeight: 600
  },
  stack: {
    alignItems: 'flex-end',
    '& .MuiInputBase-root': {
      input: {
        bgcolor: '#fff',
        borderRadius: '8px',
        py: '4px',
        fontSize: '.9em',
      },
      'fieldset': {
        border: 'none',
      },
    },
    input: {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset'
      },
    },
  },
  divider: {
    height: '2rem',
    border: 'none'
  },
  dividerM: {
    height: '1rem',
    border: 'none'
  },
  dropdown: {
    minWidth: 68,
    '& .MuiInputBase-root': {
      background: '#fff',
      borderRadius: '8px',
      '& .MuiSelect-select': {
        py: '3px',
        fontSize: '.8em',
      }
    },
    '& .MuiInputBase-input': {
      '&:focus': {
        borderColor: '#80bdff',
        boxShadow: `0 0 0 1px`,
        color: theme => theme.palette.primary.main
      },
    }
  }
}

const initialState = {
  form: {
    'first_name': {
      value: '',
      required: true,
      max: 15
    },
    'middle_name': {
      value: '',
      required: false,
      max: 15
    },
    'last_name': {
      value: '',
      required: true,
      max: 25
    },
    'city': {
      value: '',
      required: true,
    },
    'street_name': {
      value: '',
      required: true,
      max: 26
    },
    'street_type': {
      value: '',
      required: true,
      max: 10
    },
    'house_number': {
      value: '',
      required: true,
    },
    'postal_code': {
      value: '',
      required: true,
      max: 9
    },
    'state': {
      value: '',
      required: true,
      max: 2
    },
    'dob_mm': {
      value: '',
      required: false,
      max: 2
    },
    'dob_dd': {
      value: '',
      required: false,
      max: 2
    },
    'dob_yyyy': {
      value: '',
      required: false,
      max: 4
    },
    'ssn1': {
      value: '',
      required: true,
      min: 3,
      max: 3
    },
    'ssn2': {
      value: '',
      required: true,
      min: 2,
      max: 2
    },
    'ssn3': {
      value: '',
      required: true,
      min: 4,
      max: 4
    },
  },
  formErrors: {}
}

const reducer = (state = initialState, action) => {
  const newState = { ...state }
  switch (action.type) {
    case 'set':
      newState[action.state] = action.value
      return newState
    case 'setForm':
      newState.form[action.field].value = action.value
      return newState
    case 'reset':
      return initialState
    default:
      return newState
  }
}

export default function ScoreForm() {
  const {
    state,
    dispatch,
    showAlert,
    fetchProfile
  } = useContext(AppContext)
  const [formState, formDispatch] = useReducer(reducer, initialState)
  const navigate = useNavigate()

  const ssn1Ref = useRef(null)
  const ssn2Ref = useRef(null)
  const ssn3Ref = useRef(null)

  useEffect(() => {
    if (!state.loginJWT && !state.webJWT) {
      navigate('/login')
    }
    if (state.profile?.score) {
      navigate('/accounts')
    }
  }, [state.loginJWT, state.webJWT, state.profile, navigate])

  useEffect(() => {
    const ssn1 = formState.form['ssn1'].value
    const ssn2 = formState.form['ssn2'].value
    if (ssn1.length === 3) {
      ssn2Ref.current.focus()
    }
    if (ssn2.length === 2) {
      ssn3Ref.current.focus()
    }
    // eslint-disable-next-line
  }, [formState.form.ssn1.value, formState.form.ssn2.value])

  let form = formState.form
  let errors = formState.formErrors

  const validateForm = () => {
    const errors = {}
    try {
      for (const [k, v] of Object.entries(formState.form)) {
        if (v.required && !v.value) {
          errors[k] = `${k} required`
          continue
        }
        if (!v.value) continue
        if (v.min && (v.value?.length < v.min)) {
          errors[k] = `${k} min ${v.min} chars`
          continue
        }
        if (v.max && (v.value?.length > v.max)) {
          errors[k] = `${k} max ${v.max} chars`
          continue
        }
      }
      formDispatch({ type: 'set', state: 'formErrors', value: errors })
      return errors
    }
    catch(e) {
      console.log('error', e)
      formDispatch({ type: 'set', state: 'formErrors', value: errors })
      return errors
    }
  }

  const submit = async () => {
    dispatch({ type: 'show_loading' })
    try {
      const errors = validateForm()
      if (Object.keys(errors)?.length) {
        return  
      }
      let dob = ''
      if (form['dob_mm'].value && form['dob_dd'].value && form['dob_yyyy'].value) {
        dob = `${form['dob_yyyy'].value}-${form['dob_mm'].value}-${form['dob_dd'].value}`
      }
      const data = {
        first_name: form['first_name'].value,
        last_name: form['last_name'].value,
        city: form['city'].value,
        state: form['state'].value,
        postal_code: form['postal_code'].value,
        house_number: form['house_number'].value,
        street_name: form['street_name'].value,
        street_type: form['street_type'].value,
        ssn: form['ssn1'].value + form['ssn2'].value + form['ssn3'].value,
        dob
      }
      const resp = await api.score(state.webJWT, data)
      if (resp.status !== 200) {
        showAlert('We were not able to verify your identity. Did you enter the correct information? Then contact us as vs4plus@vantagescore.com')
        return
      }
      await fetchProfile(state.webJWT)
      navigate('/accounts')
    }
    catch (e) {
      console.log(e)
      showAlert('There was a problem with your request. Please try again.')
    }
    finally {
      dispatch({ type: 'close_loading' })
    }
  }

  const onChangeForm = (field, value) => {
    formDispatch({ type: 'setForm', field, value })
  }

  const renderErrors = (fields, flexEnd=false) => {
    const errs = []
    for (const f of fields) {
      if (errors[f]) {
        errs.push(errors[f])
      }
    }
    if (!errs.length) {
      return (
        <Box sx={{ height: '1.6rem' }} />
      )
    }
    return (
      <Box
        sx={{
          display:'flex',
          m:.5,
          justifyContent: flexEnd 
            ? { lg: 'flex-end', sm: 'flex-end', xs: 'flex-start' }
            : 'flex-start'
        }}
      >
        <Typography fontSize={12} color='red'>
          {errs.join(', ')}
        </Typography>
      </Box>
    )
  }

  const genMenuDates = (n, key) => {
    return Array(n).fill().map((_, i) => {
      const n = i + 1
      const value = n.toString().padStart(2, '0')
      return (
        <MenuItem key={`${key}-${i}`} value={value}>
          {value}
        </MenuItem>
      )
    })
  }

  const genMenuYears = () => {
    const end = new Date().getFullYear() - 18
    const start = 1900
    const elements = []
    for (let i = end; i >= start; i--) {
      const value = String(i)
      elements.push(
        <MenuItem key={`score-form-yyyy-${i}`} value={value}>
          {value}
        </MenuItem>
      )
    }
    return elements
  }

  return (
    <PageWrapper>
      <Box sx={style.container}>
        <Toolbar />
        <Steps />

        <Divider sx={style.divider} />

        <Box sx={style.form}>
          <Box sx={style.formBg}>
            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction='row'
              useFlexGap
              flexWrap='wrap'
              sx={style.stack}
            >
              <Box sx={style.formField}>
                <Typography sx={style.formLabel}>
                  Name
                </Typography>
                <TextField
                  required={form['first_name'].required}
                  id='cb-form-first-name'
                  size='small'
                  value={form['first_name'].value}
                  onChange={e => onChangeForm('first_name', e.target.value)}
                  // error={errors['first_name'] ? true : false}
                  // helperText={errors['first_name']}
                  placeholder='First'
                  sx={{ width: { lg: 180, sm: 180, xs: 125 }, mr: '1rem' }}
                  focused={false}
                />
              </Box>
              <Box sx={style.formField}>
                <TextField
                  required={form['middle_name'].required}
                  id='cb-form-middle-name'
                  size='small'
                  value={form['middle_name'].value}
                  onChange={e => onChangeForm('middle_name', e.target.value)}
                  // error={errors['middle_name'] ? true : false}
                  // helperText={errors['middle_name']}
                  placeholder='Middle'
                  sx={{ width: { lg: 180, sm: 180, xs: 125 } }}
                  focused={false}
                />
              </Box>
              <Box sx={style.formField}>
                <TextField
                  required={form['last_name'].required}
                  id='cb-form-last-name'
                  size='small'
                  value={form['last_name'].value}
                  onChange={e => onChangeForm('last_name', e.target.value)}
                  // error={errors['last_name'] ? true : false}
                  // helperText={errors['last_name']}
                  placeholder='Last'
                  sx={{ width: { lg: 180, sm: 180, xs: 125 } }}
                  focused={false}
                />
              </Box>
            </Stack>

          </Box>
          {renderErrors(['first_name', 'middle_name', 'last_name'])}

          <Box
            display='flex'
            justifyContent='space-between'
            flexDirection={{ lg: 'row', sm: 'row', xs: 'column' }}
          >
            <Box sx={{ ...style.formBg, mr: 1 }}>
              <Stack
                direction='row'
                sx={style.stack}
              >
                <Box sx={style.formField}>
                  <Typography sx={style.formLabel}>
                    Date of Birth
                  </Typography>
                  <Stack
                    spacing={1}
                    direction='row'
                    useFlexGap
                    flexWrap='wrap'
                    sx={style.stack}
                  >
                    <FormControl sx={style.dropdown} size='small'>
                      <Select
                        id='dob-select-mm'
                        value={form['dob_mm'].value}
                        onChange={e => onChangeForm('dob_mm', e.target.value)}
                        autoWidth
                        displayEmpty
                        MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                      >
                        <MenuItem value=''>
                          MM
                        </MenuItem>
                        {genMenuDates(12, 'score-dob-mm')}
                      </Select>
                    </FormControl>
                    <FormControl sx={style.dropdown} size='small'>
                      <Select
                        id='dob-select-dd'
                        value={form['dob_dd'].value}
                        onChange={e => onChangeForm('dob_dd', e.target.value)}
                        autoWidth
                        displayEmpty
                        MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                      >
                        <MenuItem value=''>
                          DD
                        </MenuItem>
                        {genMenuDates(31, 'score-dob-dd')}
                      </Select>
                    </FormControl>
                    <FormControl sx={style.dropdown} size='small'>
                      <Select
                        id='dob-select-yyyy'
                        value={form['dob_yyyy'].value}
                        onChange={e => onChangeForm('dob_yyyy', e.target.value)}
                        autoWidth
                        displayEmpty
                        MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                      >
                        <MenuItem value=''>
                          YYYY
                        </MenuItem>
                        {genMenuYears()}
                      </Select>
                    </FormControl>
                  </Stack>
                </Box>
              </Stack>
            </Box>
            <Box
              sx={{
                ...style.formBg,
                ml: { lg: 1, sm: 1, xs: 0 },
                mt: { lg: 0, sm: 0, xs: 3 }
              }}>
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction='row'
                useFlexGap
                flexWrap='wrap'
                sx={style.stack}
              >
                <Box sx={style.formField}>
                  <Typography sx={style.formLabel} noWrap>
                    Social Security Number
                  </Typography>
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    sx={{ '& input': { textAlign: 'center' } }}
                  >
                    <TextField
                      inputRef={ssn1Ref}
                      required={form['ssn1'].required}
                      id='cb-form-ssn'
                      size='small'
                      value={form['ssn1'].value}
                      onChange={e => onChangeForm('ssn1', e.target.value)}
                      // error={errors['ssn1'] ? true : false}
                      // helperText={errors['ssn1']}
                      sx={{ width: 60 }}
                      focused={false}
                      type='password'
                      placeholder='###'
                      onPaste={e => {
                        e.preventDefault()
                        const value = e.clipboardData.getData('Text')
                        if (typeof value !== 'string') return
                        if (value.length > 3) {
                          const ssn1 = value.slice(0, 3)
                          const ssn2 = value.slice(3, 5)
                          ssn2Ref.current.focus()
                          onChangeForm('ssn1', ssn1)
                          onChangeForm('ssn2', ssn2)
                        }
                        if (value.length > 5) {
                          const ssn3 = value.slice(5, 9)
                          onChangeForm('ssn3', ssn3)
                          ssn3Ref.current.focus()
                        }
                        return
                      }}
                    />
                    <TextField
                      inputRef={ssn2Ref}
                      required={form['ssn2'].required}
                      id='cb-form-ssn2'
                      size='small'
                      value={form['ssn2'].value}
                      onChange={e => onChangeForm('ssn2', e.target.value)}
                      // error={errors['ssn2'] ? true : false}
                      // helperText={errors['ssn2']}
                      sx={{ width: 50 }}
                      focused={false}
                      type='password'
                      placeholder='##'
                      onKeyDown={e => {
                        if (e.key === 'Backspace' && form['ssn2'].value.length === 0) {
                          ssn1Ref.current.focus()
                        }
                      }}
                    />
                    <TextField
                      inputRef={ssn3Ref}
                      required={form['ssn3'].required}
                      id='cb-form-ssn4'
                      size='small'
                      value={form['ssn3'].value}
                      onChange={e => onChangeForm('ssn3', e.target.value)}
                      // error={errors['ssn3'] ? true : false}
                      // helperText={errors['ssn3']}
                      sx={{ width: 65 }}
                      focused={false}
                      type='password'
                      placeholder='####'
                      onKeyDown={e => {
                        if (e.key === 'Backspace' && form['ssn3'].value.length === 0) {
                          ssn2Ref.current.focus()
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Box>

          {renderErrors(['ssn1', 'ssn2', 'ssn3'], true)}

          <Box sx={style.formBg}>
            <Typography sx={style.formLabel}>
              Address
            </Typography>
            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction='row'
              useFlexGap
              flexWrap='wrap'
              sx={style.stack}
            >
              <Box sx={style.formField}>
                <TextField
                  required={form['house_number'].required}
                  id='cb-form-house-number'
                  size='small'
                  value={form['house_number'].value}
                  onChange={e => onChangeForm('house_number', e.target.value)}
                  // error={errors['house_number'] ? true : false}
                  // helperText={errors['house_number']}
                  sx={{ width: 100, mr: 'auto' }}
                  focused={false}
                  placeholder='House No.'
                />
              </Box>
              <Box sx={style.formField}>
                <TextField
                  required={form['street_name'].required}
                  id='cb-form-street-name'
                  size='small'
                  value={form['street_name'].value}
                  onChange={e => onChangeForm('street_name', e.target.value)}
                  // error={errors['street_name'] ? true : false}
                  // helperText={errors['street_name']}
                  sx={{ width: { lg: 280, sm: 290, xs: 170 } }}
                  focused={false}
                  placeholder='Street'
                />
              </Box>
              <Box sx={style.formField}>
                <TextField
                  required={form['street_type'].required}
                  id='cb-form-street-type'
                  size='small'
                  value={form['street_type'].value}
                  onChange={e => onChangeForm('street_type', e.target.value)}
                  // error={errors['street_type'] ? true : false}
                  // helperText={errors['street_type']}
                  sx={{ width: 100 }}
                  focused={false}
                  placeholder='St. Type'
                />
              </Box>
              <Box sx={style.formField}>
                <TextField
                  required={form['city'].required}
                  id='cb-form-city'
                  size='small'
                  value={form['city'].value}
                  onChange={e => onChangeForm('city', e.target.value)}
                  // error={errors['city'] ? true : false}
                  // helperText={errors['city']}
                  sx={{ width: { lg: 180, sm: 180, xs: 200 }}}
                  focused={false}
                  placeholder='City'
                />
              </Box>
              <FormControl sx={{ ...style.dropdown, minWidth: 80 }} size='small'>
                <Select
                  id='state-select'
                  value={form['state'].value}
                  onChange={e => onChangeForm('state', e.target.value)}
                  autoWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                >
                  <MenuItem value=''>
                    State
                  </MenuItem>
                  {states.map(({ value }, i) => (
                    <MenuItem key={`score-state-${i}`}value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box sx={style.formField}>
                <TextField
                  required={form['postal_code'].required}
                  id='cb-form-postal_code'
                  size='small'
                  value={form['postal_code'].value}
                  onChange={e => onChangeForm('postal_code', e.target.value)}
                  // error={errors['postal_code'] ? true : false}
                  // helperText={errors['postal_code']}
                  sx={{ width: { lg: 160, sm: 160, xs: 140 } }}
                  focused={false}
                  placeholder='Postal'
                />
              </Box>
            </Stack>
          </Box>


          <Box sx={style.formRow}>
          </Box>
          {renderErrors(['house_number', 'street_name', 'street_type', 'city', 'state', 'postal_code'])}

          <Divider sx={style.divider} />

          <Box sx={style.formButton}>
            <Button
              variant='customContained'
              onClick={submit}
            >
              Next
            </Button>
          </Box>
          <Divider sx={style.dividerM} />
        </Box>
      </Box>
    </PageWrapper>
  )
}
