import * as React from 'react'
import Box from '@mui/material/Box'
import Header from '../header/header'
import CircularProgress from '@mui/material/CircularProgress'

import Footer from './footer'

export default function AppLoading() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Header />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '90vh'
        }}
      >
        <CircularProgress size={50} color='secondary' />
      </Box>
      <Footer />
    </Box>
  )
}
