import React, { useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import { AppContext } from '../../App'

export default function LoadJWT() {
  const navigate = useNavigate()
  const { jwt } = useParams()
  const { fetchToken, dispatch } = useContext(AppContext)

  useEffect(() => {
    if (!jwt) return
    dispatch({
      type: 'set',
      state: 'loginJWT',
      value: jwt
    })
    fetchToken(jwt)
    navigate('/')
    // eslint-disable-next-line
  }, [])

  return (
    <Box
      component='main'
      display='flex'
      justifyContent='center'
      alignItems='center'
      minHeight='100vh'
      bgcolor='#212121'
    >
    </Box>
  )
}
