import React, { useEffect, useContext } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded'

import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../App'
import centerImg from '../../images/page1_center.svg'
import stepIcon1 from '../../images/step_icon_1.svg'
import stepIcon2 from '../../images/step_icon_2.svg'
import stepIcon3 from '../../images/step_icon_3.svg'
import stepIcon4 from '../../images/step_icon_4.svg'

import PageWrapper from './page_wrapper'

const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: { lg: 'calc(100vh - 80px)', sm: 'calc(100vh - 140px)', xs: '100vh' },
    width: '100%',
  },
  flexColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  bodyTop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: {
      lg: 'row',
      sm: 'row',
      xs: 'column-reverse'
    },
    '& img': {
      width: {
        lg: 480,
        sm: 350
      }
    },
    py: {
      lg: 0,
      sm: 8
    }
  },
  divider: {
    bgcolor: theme => theme.palette.secondary.main,
    border: 'none',
    height: '1rem',
    width: '100%'
  },
  stepsColumnM: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 700,
    minHeight: 100
  },
  stepsIconRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 755,
    mb: 1
  },
  stepsTextRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 800,
  },
  stepsRowFill: {
    width: '100%',
    bgcolor: '#FD3E39',
    height: 120,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  stepsIcon: {
    height: 100
  },
  stepsText: {
    fontSize: '1.1em',
    fontWeight: 600,
    color: '#fff',
    width: 140,
    textAlign: 'center',
    lineHeight: 1.2
  },
  numberCircle: {
    p: 2,
    bgcolor: theme => theme.palette.primary.main,
    borderRadius: 10,
    height: 48,
    width: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  numberCircleM: {
    p: 2,
    bgcolor: theme => theme.palette.primary.main,
    borderRadius: 10,
    height: 32,
    width: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  number: {
    fontWeight: 600,
    color: '#fff',
    fontSize: '1.2em'
  },
  numberM: {
    fontWeight: 600,
    color: '#fff',
    fontSize: '.9em'
  },
  numberArrow: {
    color: theme => theme.palette.secondary.main,
    position: 'relative',
    left: -21,
    top: 48,
  },
  dotted: {
    border: 'none',
    borderTop: `3px dotted`,
    borderColor: theme => theme.palette.secondary.main,
    width: 650,
    position: 'relative',
    top: -31,
    zIndex: -1
  }
}

const steps = [
  {
    step: '1',
    icon: stepIcon1,
    text: 'Consent to VantageScore4<sup>plus</sup> Pilot Program'
  },
  {
    step: '2',
    icon: stepIcon2,
    text: 'Identity Verification'
  },
  {
    step: '3',
    icon: stepIcon3,
    text: 'Find and Connect to Your Bank Accounts'
  },
  {
    step: '4',
    icon: stepIcon4,
    text: 'Sit back while VantageScore4<sup>plus</sup> is being generated'
  },
]

export default function Home() {
  const navigate = useNavigate()
  const { state, breakSm } = useContext(AppContext)

  useEffect(() => {
    if (!state.loginJWT && !state.webJWT) {
      navigate('/login')
    }
  }, [state.loginJWT, state.webJWT, navigate])

  const renderSteps = () => {
    if (breakSm) {
      return (
        <>
          {steps.map((step, idx) => (
            <Stack
              key={`steps-mobile-${idx}`}
              spacing={1}
              justifyContent='center'
              alignItems='center'
              width='100%'
              py={2}
            >
              <Box sx={style.numberCircleM}>
                <Typography sx={style.numberM}>
                  {step.step}
                </Typography>
              </Box>
              <img src={step.icon} alt='steps' style={{ height: 50 }} />
              <Box sx={{ ...style.stepsRowFill, height: 80 }}>
                <Typography
                  dangerouslySetInnerHTML={{ __html: step.text }}
                  sx={{ ...style.stepsText, fontSize: '1em' }}>
                  {/*{step.text}*/}
                </Typography>
              </Box>
            </Stack>
          ))}
        </>
      )
    }
    return (
      <>
        <Box sx={style.stepsRow}>
          {steps.map(({ step }, idx) => (
            <Box key={`home-steps-${idx}`}>
              {idx !== 0
                ? <ArrowRightRoundedIcon sx={style.numberArrow} fontSize='large' />
                : <ArrowRightRoundedIcon sx={{ color: 'transparent' }} fontSize='large' />}
              <Box sx={style.numberCircle}>
                <Typography sx={style.number}>
                  {step}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Box sx={style.dotted} />
        <Box sx={style.stepsIconRow}>
          {steps.map(({ icon }, idx) => (
            <img key={`home-steps-icon-${idx}`} src={icon} alt='steps' style={style.stepsIcon} />
          ))}
        </Box>
        <Box sx={style.stepsRowFill}>
          <Box sx={style.stepsTextRow}>
            {steps.map(({ text }, idx) => (
              <Typography
                dangerouslySetInnerHTML={{ __html: text }}
                key={`home-steps-text-${idx}`}
                sx={style.stepsText}>
                {/*{text}*/}
              </Typography>
            ))}
          </Box>
        </Box>
      </>
    )
  }

  return (
    <PageWrapper>
      <Box sx={style.container}>
        <Box sx={style.bodyTop}>
          <Box sx={style.flexColumn} width={{ lg: 475, sm: 375, xs: 275 }}>
            <Typography
              color='primary'
              fontWeight={600}
              width={{ lg: 380, sm: 280, xs: 200 }}
              lineHeight={1.2}
              fontSize={{ lg: '3rem', sm: '2rem', xs: '1.5rem' }}
              textAlign={{ lg: 'left', xs: 'center' }}
            >
              Your VantageScore4<sup>plus</sup> Journey Continues with 4 Simple Steps
            </Typography>
            <Button
              variant='customContained'
              sx={{ my: 4 }}
              onClick={() => navigate('consent')}
            >
              Start Now
            </Button>
          </Box>
          <img src={centerImg} alt='center' />
        </Box>

        <Divider sx={style.divider} />

        {renderSteps()}
      </Box>
    </PageWrapper>
  )
}
