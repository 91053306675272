import React, { useContext } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { AppContext } from '../../App'
import logo from '../../images/logo_white_vs.png'

export default function Header() {
  const { state, handleLogout } = useContext(AppContext)
  return (
    <AppBar
      position='fixed'
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: (theme) => theme.palette.gradient.main,
        display: 'flex',
        height: 90
      }}
    >
      <Toolbar sx={{ height: '100%' }}>
        <Typography
          variant='h5'
          noWrap
          component='div'
          color='primary'
          sx={{
            flexGrow: 1,
            display: 'flex',
            '& img': {
              height: {
                lg: 50,
                sm: 50,
                xs: 30
              }
            }
          }}
        >
          <img src={logo} alt='logo' />
        </Typography>
        {state.webJWT &&
          <Button
            variant='customOutlinedWhite'
            size='small'
            onClick={handleLogout}
          >
            Log out
          </Button>
        }
      </Toolbar>
    </AppBar>
  )
}
