import React, {
  useState,
  useContext,
  useEffect
} from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import Divider from '@mui/material/Divider'

import { AppContext } from '../../App'
import api from '../../util/api'
import { formatDateY } from '../../util/date'
import { decodeJWT } from '../../util/jwt'

const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: { lg: 600, sm: 600, xs: 320 },
    textAlign: 'justify'
  },
  divider: {
    height: '1rem',
    border: 'none'
  },
  formField: {
    display: 'flex',
    flexDirection: 'column',
  },
  formRow: {
    display: 'flex',
    alignItems: 'flex-end',
    width: 250,
    '& .MuiInputBase-root': {
      borderRadius: 0,
      'fieldset': {
        textAlign: 'center',
        borderColor: theme => theme.palette.primary.main,
        borderRight: 'none',
        borderLeft: 'none',
      },
      ':hover fieldset': {
        borderColor: theme => theme.palette.primary.main
      },
    },
    '& .MuiTextField-root:first-of-type .MuiOutlinedInput-notchedOutline': {
      borderLeft: '1px solid',
      borderColor: theme => theme.palette.primary.main,
      borderTopLeftRadius: '20px',
      borderBottomLeftRadius: '20px',
    },
    '& .MuiTextField-root:last-of-type .MuiOutlinedInput-notchedOutline': {
      borderRight: '1px solid',
      borderColor: theme => theme.palette.primary.main,
      borderTopRightRadius: '20px',
      borderBottomRightRadius: '20px',
    },
    '& .MuiTextField-root:first-of-type': {
      input: {
        pr: 1,
      }
    },
    '& .MuiTextField-root:last-of-type': {
      input: {
        pl: 1
      }
    },
    input: {
      textAlign: 'center',
      px: 5,
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset'
      }
    }
  },
  rowBetween: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}

export default function PersonForm() {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [loading, setLoading] = useState(false)
  const [decodedLoginJWT, setDecodedLoginJWT] = useState(false)

  const {
    state,
    fetchToken,
    showAlert
  } = useContext(AppContext)

  useEffect(() => {
    if (state.loginJWT) {
      const decoded = decodeJWT(state.loginJWT)
      setDecodedLoginJWT(decoded)
    }
  }, [state.loginJWT])

  const handleContinue = async () => {
    setLoading(true)
    try {
      const data = {
        first_name: firstName,
        last_name: lastName,
        token: state.loginJWT
      }
      const resp = await api.signup(data)
      if (resp.status === 201) {
        fetchToken(state.loginJWT)
        return
      }
      showAlert('There was a problem with your request, please try again.')
    }
    catch (e) {
      showAlert('There was a problem with your request, please try again.')
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <Box sx={style.container}>
      <Typography
        color='primary'
        fontWeight={600}
        textAlign='center'
        fontSize={{ lg: '1.25rem', sm: '1.25rem', xs: '1.1rem' }}
      >
        Consent to participate in VantageScore4<sup>plus</sup> pilot program
        <br />
        Part 2 of 2
      </Typography>
      <Divider sx={{ height: '2rem', border: 'none' }} />
      <Typography variant='h4' textAlign='center' fontWeight={600} color='text.secondary'>
        Personal Information
      </Typography>
      <Typography variant='h12' color='text.secondary' sx={{ '& p': { my: 3 } }}>
        <p>
          I understand that by clicking ACCEPT and typing my name I am
          providing my consent electronically and that it has the same force
          and effect as if I was signing in person on paper.
        </p>
        <p>
          Your decision to participate in this VantageScore4<sup>plus</sup> Demo is
          completely voluntary and you may decline to participate
          or stop your participation at any time during this
          user experience demonstration.
        </p>
        <p>
          Pentadata will receive your name and email address to provide
          you the link to participate in the Pilot Program experience
          and to record your consent to participation,
          but <b>will not be used for any other purposes</b>.
        </p>
      </Typography>

      <Divider sx={style.divider} />

      <Box
        justifyContent='space-between'
        display='flex'
        flexDirection={{ lg: 'row', sm: 'row', xs: 'column' }}
      >
        <Typography variant='body1' color='text.secondary'>
          Email: {decodedLoginJWT.email}
        </Typography>
        <Typography
          variant='h8'
          mr={{ lg: 10, xs: 0 }}
          color='text.secondary'
          textAlign={{ lg: 'justify', xs: 'left' }}
        >
          Date signed: {formatDateY(new Date())}
        </Typography>
      </Box>

      <Divider sx={style.divider} />

      <Box component='form' sx={style.formRow}>
        <TextField
          required
          id='person-form-first-name'
          // label='First Name'
          size='small'
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          focused={false}
          placeholder='First Name'
        />
        <TextField
          required
          id='person-form-last-name'
          // label='Last Name'
          size='small'
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          focused={false}
          placeholder='Last Name'
        />
      </Box>

      <Divider sx={style.divider} />

      <Box sx={{ display: 'flex' }}>
        <LoadingButton
          variant={(!firstName || !lastName || lastName.length < 2) ? 'customContainedDisabled' : 'customContained'}
          onClick={handleContinue}
          disabled={!firstName || !lastName || lastName.length < 2}
          loading={loading}
        >
          ACCEPT
        </LoadingButton>
      </Box>
    </Box>
  )
}
