import * as React from 'react'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Header from '../header/header'

import Footer from './footer'

export default function PageWrapper({ children }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Header />
      <Toolbar />
      {children}
      <Footer />
    </Box>
  )
}
